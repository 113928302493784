import 'regenerator-runtime/runtime';
import init from './main';
import 'core-js';
import 'mdn-polyfills/Array.prototype.forEach';
import 'mdn-polyfills/Element.prototype.closest';
import 'fetch-ie8';
require('es6-promise').polyfill();

// ie CSS Variables polyfill
if (!(window.CSS && window.CSS.supports('color', 'var(--primary)'))) {
	import(
		/* webpackChunkName: "ie11CustomProperties" */ './ie11CustomProperties'
	);
}

// ie nodelist polyfill
if (window.NodeList && !window.NodeList.prototype.forEach) {
	window.NodeList.prototype.forEach = Array.prototype.forEach;
}

// Provides support for DOMTokenList.prototype.toggle, with the optional
// "force" parameter, in legacy browsers.
// Support: IE
// Source: https://github.com/mozilla/pdf.js/blob/master/src/shared/compatibility.js
(function checkDOMTokenListToggle() {
	const div = document.createElement('div');
	if (div.classList.toggle('test', 0) === false) {
		return;
	}

	DOMTokenList.prototype.toggle = function (token) {
		const force = arguments.length > 1 ? !!arguments[1] : !this.contains(token);
		return this[force ? 'add' : 'remove'](token), force;
	};
})();

// Provides support for Element.toggleAttribute, with the optional
// "force" parameter, in legacy browsers.
// Support: IE
// Source: https://developer.mozilla.org/en-US/docs/Web/API/Element/toggleAttribute
(function checkElementToggleAttribute() {
	if (!Element.prototype.toggleAttribute) {
		Element.prototype.toggleAttribute = function (name, force) {
			if (force !== void 0) force = !!force;

			if (this.hasAttribute(name)) {
				if (force) return true;

				this.removeAttribute(name);
				return false;
			}
			if (force === false) return false;

			this.setAttribute(name, '');
			return true;
		};
	}
})();

init();
